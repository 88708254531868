import React from "react";

function Action() {
  const amazon = {
    width: "120px",
    height: "240px",
    margin: "60px",
  };
  return (
    <>
      <div className="act">
        <div className="mic">
          <h3>Course 1</h3>
          <div className="yu">
            <a href="https://aff.stakecut.com/116/27034">
              <button>
                <strong>Start Course</strong>
              </button>
            </a>
          </div>
        </div>
        <div>
          <iframe
            sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
            style={amazon}
            title="original affiliate course"
            marginwidth="0"
            marginheight="0"
            scrolling="no"
            frameborder="0"
            src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=elisha24356-20&language=en_US&marketplace=amazon&region=US&placement=B0BJL8PW5M&asins=B0BJL8PW5M&linkId=ee06dda653767bdea8a8612a29c82552&show_border=true&link_opens_in_new_window=true"
          ></iframe>
          <p>OR</p>
        <button>
          <a href="https://amzn.to/3HCwjzJ">Shop Here</a>
        </button>
          
        </div>
      </div>
    </>
  );
}

export default Action;
