import React from "react";
import { Link } from "react-router-dom";
const Prod = () => {
  const amazon = {
    width: "120px",
    height: "240px",
    margin: "20px",
  };

  return (
    <>
      <div className="backg">
        <img
          src="./images/coffee.jpg"
          alt="back"
          width="100%"
          height="100%"
        ></img>
        <div className="text">
          <span id="texte" className="texte">
            Unlock Your Financial Potential
          </span>
          <span className="texto">
            Learn Proven Strategies for Making Money and Building a Successful
            Side Hustle
          </span>

          <button>
            <a href="#learn">Learn How</a>
          </button>
        </div>
      </div>
      <div className="down" id="learn">
        <div className="left">
          <img src="./images/bac.jpg" alt="" height="100%" width="100%" />
        </div>
        <div className="right">
          <h3>
            Achieving Financial Success: A Comprehensive Guide to Making Money
          </h3>
          <span>
            Take the first step towards financial freedom today by purchasing
            our comprehensive guide on how to make money
          </span>
          <span>
            Written by experts in the field, this book is packed with proven
            strategies and insider tips to help you increase your income and
            achieve your financial goals. Don't wait any longer to take control
            of your financial future
          </span>
          <span>Order now and start seeing real results.</span>
        </div>
      </div>
      <div className="container">
        <div className="s">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="black"
              fill-opacity="1"
              d="M0,192L48,160C96,128,192,64,288,64C384,64,480,128,576,144C672,160,768,128,864,128C960,128,1056,160,1152,176C1248,192,1344,192,1392,192L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
        <div className="g">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="black"
              fill-opacity="1"
              d="M0,32L48,69.3C96,107,192,181,288,213.3C384,245,480,235,576,240C672,245,768,267,864,282.7C960,299,1056,309,1152,282.7C1248,256,1344,192,1392,160L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
            ></path>
          </svg>
        </div>
        <div className="overlap">
          <span>
            This platform guides you from honing your inner skills and achieving
            financial freedom
          </span>
          <br></br>

          <span>
            Take control of your financial future with just one purchase.
          </span>
          <span>
            <i class="fa fa-arrow-down"></i>
          </span>
        </div>
      </div>

      <div class="Products">
        <div className="bo">
          <i class="fa fa-book"></i>
          <h3>E-book Products</h3>
        </div>
        <div className="first">
          <h3>Step 1: Time Management</h3>
          <span>
            Time is a created thing. To say 'I don't have time', is like saying,
            'I don't want to
          </span>

          <span className="TO"> - Lao Tzu</span>
          <br></br>
          <span>
            This book will guide you thoroughly on how to manage your time
          </span>
        </div>
        <iframe
          sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
          marginwidth="0"
          marginheight="0"
          title="time management"
          scrolling="no"
          frameborder="0"
          src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=elisha24356-20&language=en_US&marketplace=amazon&region=US&placement=1416626654&asins=1416626654&linkId=38f6d87c7fb8123e45e52090d72b08b3&show_border=true&link_opens_in_new_window=false"
        ></iframe>
        <p>OR</p>
        <button>
          <a href="https://amzn.to/40xwIw0">Shop Here</a>
        </button>
        <div className="second">
          <h3>Step 2: Personality Development</h3>
          <span>
            The only limit to our realization of tomorrow will be our doubts of
            today.
          </span>

          <span className="TO"> - Franklin D. Roosevelt</span>
          <br></br>
          <span>
            This book will guide you how to achieve personal development
          </span>
        </div>
        <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" style={amazon} title="personal development" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=elisha24356-20&language=en_US&marketplace=amazon&region=US&placement=146253693X&asins=146253693X&linkId=a07b9fc575f1ef51b6cd9289a729b972&show_border=true&link_opens_in_new_window=true"></iframe>
        <p>OR</p>
        <button>
          <a href="https://amzn.to/3Husaho">Shop Here</a>
        </button>
        <div className="last">
          <h3>Final Step: Getting Into Action</h3>
          <span>
            How do you know how to start , how to continue and the success of it
            all. Purchase the Three Combo to that success
          </span>
          <br></br>
          <span>
            The future belongs to those who believe in the beauty of their
            dreams.
          </span>

          <span className="TO"> - Eleanor Roosevelt</span>
          <br></br>
          <span>
            This book will guide you thoroughly on how to achieve that
          </span>
        </div>
        <div className="prod">
          <iframe
            sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
            style={amazon}
            marginwidth="0"
            marginheight="0"
            scrolling="no"
            frameborder="0"
            title="first combo book"
            src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=elisha24356-20&language=en_US&marketplace=amazon&region=US&placement=1524758841&asins=1524758841&linkId=5cc527f7be358f25537493023e45e864&show_border=true&link_opens_in_new_window=false"
          ></iframe>
          <p>OR</p>
          <button>
            <a href="https://amzn.to/3HBh8a9">Shop Here</a>
          </button>

          <iframe
            style={amazon}
            sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
            marginwidth="0"
            marginheight="0"
            scrolling="no"
            title="second combo book"
            frameborder="0"
            src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=elisha24356-20&language=en_US&marketplace=amazon&region=US&placement=0307951529&asins=0307951529&linkId=98b0a36c4222801da0918654f2addf52&show_border=true&link_opens_in_new_window=true"
          ></iframe>
          <p>OR</p>
          <button>
            <a href="https://amzn.to/40wSOyY">Shop Here</a>
          </button>

          <iframe
            sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
            style={amazon}
            title="third combo book"
            marginwidth="0"
            marginheight="0"
            scrolling="no"
            frameborder="0"
            src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=elisha24356-20&language=en_US&marketplace=amazon&region=US&placement=0399582576&asins=0399582576&linkId=4e3af1e4e97a2e1d966a3336c2d95758&show_border=true&link_opens_in_new_window=true"
          ></iframe>
          <p>OR</p>
          <button>
            <a href="https://amzn.to/3Y3b5lV">Shop Here</a>
          </button>
        </div>
      </div>

      <div className="now">
        <span>
          <h3>What After These?</h3>
        </span>
        <p className="po">
          We have list of courses to guide and teach you how to earn passive
          income.
        </p>
        <Link to="/programmes">
          <button>
            <i class="fa fa-money-bill"></i>
            Earn Now
          </button>
        </Link>
      </div>
    </>
  );
};

export default Prod;
