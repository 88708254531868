import React from "react";

function How() {
  const amazon = {
    width: "120px",
    height: "240px",
    margin: "20px",
  };
  return (
    <>
      {" "}
      <iframe
        sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
        style={amazon}
        title="choosing your means of earning passively"
        marginwidth="0"
        marginheight="0"
        scrolling="no"
        frameborder="0"
        src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=elisha24356-20&language=en_US&marketplace=amazon&region=US&placement=B09784PFSB&asins=B09784PFSB&linkId=97b00922960b388a98463b8ecd5509f1&show_border=true&link_opens_in_new_window=true"
      ></iframe>
      <p>OR</p>
      <button>
        <a href="https://amzn.to/40yXGDx">Shop Here</a>
      </button>
    </>
  );
}

export default How;
