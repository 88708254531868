import "./App.css";
import Header from "./components/Header";
import Products from "./components/Products";
import Developer from "./components/Developer";
import How from "./components/How";
import Action from "./components/Action";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";

function App() {
  const amazon = {
    width: "120px",
    height: "240px",
    margin: "20px",
  };
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Products />}></Route>

          <Route path="/programmes" element={<Developer />}></Route>
          <Route path="/how" element={<How />}></Route>
          <Route path="/action" element={<Action />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
